import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import { registerLicense } from "@syncfusion/ej2-base";
import viber from "../../img/viber.png";
import whatsapp from "../../img/whatsapp.png";
import phone from "../../img/phone.png";

import {
  DatePickerComponent,
  TimePickerComponent,
} from "@syncfusion/ej2-react-calendars";
import "./style.css";

registerLicense(
  "ORg4AjUWIQA/Gnt2VVhiQlFadVlJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdk1jXX9cc3dRR2BbWEM="
);
const FindCar = () => {
  const { t } = useTranslation();
  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  return (
    <section className="gauto-find-area">
      <Container>
        <Row>
          <Col md={12}>
            <div className="find-box">
              <Row className="align-items-center">
                <Col md={4}>
                  <div className="find-text">
                    <h3>{t("search_best_car")}</h3>
                  </div>
                </Col>
                <Col md={8}>
                  <div className="find-form">
                    <form onSubmit={SubmitHandler}>
                      <Row>
                        <Col md={4}>
                          <a href="viber://pa?chatURI=+38761147169">
                            <img alt="Viber" src={viber} style={{ height: "80px", paddingLeft: "40px" }}/>
                          </a>
                        </Col>
                        <Col md={4}>
                        <a href="https://wa.me/+38761147169">
                            <img alt="Whatsapp" src={whatsapp} style={{ height: "80px", paddingLeft: "40px" }}/>
                          </a>
                        </Col>
                        <Col md={4}>
                        <a href="tel:+387603151335">
                            <img alt="WhaPhonetsapp" src={phone} style={{ height: "80px", paddingLeft: "40px" }}/>
                          </a>
                        </Col>
                      </Row>
                    </form>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default FindCar;
