import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import { FaPhoneAlt } from "react-icons/fa";

import img1 from "../../img/about-page.jpg";
import img2 from "../../img/cars.png";

import "./style.css";

const AboutPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className="about-page-area section_70">
        <Container>
          <Row>
            <Col lg={6}>
              <div className="about-page-left">
                <h4>{t("about_page.subtitle")}</h4>
                <h3>{t("about_page.title")}</h3>
                <p>{t("about_page.welcome")}</p>
                <h3>{t("about_page.mission")}</h3>
                <p>{t("about_page.mission_text")}</p>
                <h3>{t("about_page.sets_apart")}</h3>
                <ul>
                  <h4>{t("about_page.sets_apart_1_h")}</h4>
                  <li>{t("about_page.sets_apart_1_b")}</li>
                  <h4>{t("about_page.sets_apart_2_h")}</h4>
                  <li>{t("about_page.sets_apart_2_b")}</li>
                  <h4>{t("about_page.sets_apart_3_h")}</h4>
                  <li>{t("about_page.sets_apart_3_b")}</li>
                  <h4>{t("about_page.sets_apart_4_h")}</h4>
                  <li>{t("about_page.sets_apart_4_b")}</li>
                </ul>
                <h3>{t("about_page.commitment")}</h3>
                <p>{t("about_page.commitment_text")}</p>
                <h3>{t("about_page.sustainability")}</h3>
                <p>{t("about_page.sustainability_text")}</p>
                <h3>{t("about_page.why_chose_us")}</h3>
                <ul>
                  <li>•	{t("about_page.why_chose_us_1")}</li>
                  <li>•	{t("about_page.why_chose_us_2")}</li>
                  <li>•	{t("about_page.why_chose_us_3")}</li>
                  <li>•	{t("about_page.why_chose_us_4")}</li>
                </ul>
                <h3>{t("about_page.join_family")}</h3>
                <p></p>
                <h4>{t("about_page.hit_road")}</h4>
                <div className="about-page-call">
                  <div className="page-call-icon">
                    <FaPhoneAlt />
                  </div>
                  <div className="call-info">
                    <p>{t("need_any_help")}</p>
                    <h4>
                      <a href="tel:+38761147169">+38761147169</a>
                    </h4>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="about-page-right">
                <img src={img1} alt="about page" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="gauto-about-promo section_70">
        <Container>
          <Row>
            <Col md={12}>
              <div className="about-promo-text">
                <h3
                  dangerouslySetInnerHTML={{
                    __html: t("about_page.proud_title", {
                      interpolation: { escapeValue: false },
                    }),
                  }}
                ></h3>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="about-promo-image">
                <img src={img2} alt="about promo" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default AboutPage;
