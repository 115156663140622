import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import {
  FaHeart,
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaSkype,
  FaPaperPlane,
} from "react-icons/fa";

import logo from "../../img/logo2.png";
import img1 from "../../img/post-thumb-3.jpg";
import img2 from "../../img/post-thumb-2.jpg";
import img3 from "../../img/post-thumb-1.jpg";

import "./style.css";

const Footer = () => {
  const { t } = useTranslation();

  const onClick = (e) => {
    e.preventDefault();
  };

  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  return (
    <footer className="gauto-footer-area">
      <div className="footer-top-area">
        <Container>
          <Row>
            <Col lg={4}>
              <div className="single-footer">
                
                <div className="footer-address">
                  <h3>{t("footer.head_office")}</h3>
                  <p>
                    Dzemala Bijedica St 185 <span>Sarajevo 71000</span>
                  </p>
                  <ul>
                    <li>{t("footer.phone")}: +38761147169 </li>
                    <li>{t("footer.email")}: edessarentacar@gmail.com</li>
                    <li>{t("footer.office_time")}: 9AM - 6PM</li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col lg={4}>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
