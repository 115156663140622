import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import { FaQuoteRight } from "react-icons/fa";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import img1 from "../../img/testimonial.jpg";
import img2 from "../../img/testimonial-2.jpg";

import "./style.css";

const Testimonial = () => {
  const { t } = useTranslation();

  const settings = {
    dots: true,
    arrows: false,
    speed: 1200,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 2500,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          infinite: true,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <section className="gauto-testimonial-area section_70">
      <Container>
        <Row>
          <Col md={12}>
            <div className="site-heading">
              <h4>{t("some_words")}</h4>
              <h2>{t("testimonial")}</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Slider className="testimonial-slider" {...settings}>
              <div className="slide">
                <div className="single-testimonial">
                  <div className="testimonial-text">
                    <p>
                      "We’ve rented a car twice in Sarajevo and in Bosnia.
                      The first time, the car was delivered to our hotel and picked up again, and the second time, we were greeted at the airport.
                      All arrangements were carried out to our complete satisfaction. The cars were in very, very good condition."
                    </p>
                    <div className="testimonial-meta">
                      <div className="client-info">
                        <h3>Hanna Müller</h3>
                        <p>{t("customer")}</p>
                      </div>
                    </div>
                    <FaQuoteRight />
                  </div>
                </div>
              </div>
              <div className="slide">
                <div className="single-testimonial">
                  <div className="testimonial-text">
                    <p>
                      "We had a rental car in Sarajevo for 1 week.
                      Perfect service, hotel delivery (Hotel Radon Plaza) and pick-up, everything went perfectly.
                      Good instructions for the rental car and helpful tips for the city and surroundings.
                      I would rent a car again at any time. Greets to the friendly staff."
                    </p>
                    <div className="testimonial-meta">
                      <div className="client-info">
                        <h3>Abdur Raqib Al-Khelaifi</h3>
                        <p>{t("customer")}</p>
                      </div>
                    </div>
                    <FaQuoteRight />
                  </div>
                </div>
              </div>
              <div className="slide">
                <div className="single-testimonial">
                  <div className="testimonial-text">
                    <p>
                      "3 günlüğüne küçük bir araba kiraladık. Her şey harika bir şekilde sonuçlandı.
                      Araba havaalanında bize getirildi. Devir teslim süper dakikti, her şey düzgün bir şekilde açıklandı.
                      Herhangi bir sorun olması durumunda hemen iletişime geçebilmemiz için telefon numarası değiştirildi. Bu nedenle çok memnun kaldık."
                    </p>
                    <div className="testimonial-meta">
                      <div className="client-info">
                        <h3>Mete Buğdaycı</h3>
                        <p>{t("customer")}</p>
                      </div>
                    </div>
                    <FaQuoteRight />
                  </div>
                </div>
              </div>
              <div className="slide">
                <div className="single-testimonial">
                  <div className="testimonial-text">
                    <p>
                      "Ljubazno osoblje, dobro posredovanje, korektne cijene.
                      Kada sam zaboravio skupe naočare u pretincu za rukavice dok sam grad skakao Bosnom na prvom gradu,
                      dogovorili su da ih preuzmem na aerodromu prije polaska!
                      Topla preporuka i još jednom puno hvala na svemu!
                      "
                    </p>
                    <div className="testimonial-meta">
                      <div className="client-info">
                        <h3>Marko Brkić</h3>
                        <p>{t("customer")}</p>
                      </div>
                    </div>
                    <FaQuoteRight />
                  </div>
                </div>
              </div>
            </Slider>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Testimonial;
