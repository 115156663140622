import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import { FaPhoneAlt } from "react-icons/fa";

import img1 from "../../img/rulespage.jpg";
import img2 from "../../img/cars.png";

import "./style.css";

const TermsPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className="about-page-area section_70">
        <Container>
          <Row>
            <Col lg={6}>
              <div className="about-page-left">
                <h4>{t("terms_page.subtitle")}</h4>
                <h3>{t("terms_page.title")}</h3>
                  <ul>
                    <li><h4>{t("terms_page.rule_1_h")}</h4></li>
                    <li>{t("terms_page.rule_1_b")}</li>
                    <li><h4>{t("terms_page.rule_2_h")}</h4></li>
                    <li>{t("terms_page.rule_2_b")}</li>
                    <li><h4>{t("terms_page.rule_3_h")}</h4></li>
                    <li>{t("terms_page.rule_3_b")}</li>
                    <li><h4>{t("terms_page.rule_4_h")}</h4></li>
                    <li>{t("terms_page.rule_4_b")}</li>
                    <li><h4>{t("terms_page.rule_5_h")}</h4></li>
                    <li>{t("terms_page.rule_5_b")}</li>
                    <li><h4>{t("terms_page.rule_6_h")}</h4></li>
                    <li>{t("terms_page.rule_6_b")}</li>
                    <li><h4>{t("terms_page.rule_7_h")}</h4></li>
                    <li>{t("terms_page.rule_7_b")}</li>
                    <li><h4>{t("terms_page.rule_8_h")}</h4></li>
                    <li>{t("terms_page.rule_8_b")}</li>
                    <li><h4>{t("terms_page.rule_9_h")}</h4></li>
                    <li>{t("terms_page.rule_9_b")}</li>
                    <li><h4>{t("terms_page.rule_10_h")}</h4></li>
                    <li>{t("terms_page.rule_10_b")}</li>
                    <li><h4>{t("terms_page.rule_11_h")}</h4></li>
                    <li>{t("terms_page.rule_11_b")}</li>
                    <li><h4>{t("terms_page.rule_12_h")}</h4></li>
                    <li>{t("terms_page.rule_12_b")}</li>
                    <li><h4>{t("terms_page.rule_13_h")}</h4></li>
                    <li>{t("terms_page.rule_13_b")}</li>
                    <li><h4>{t("terms_page.rule_14_h")}</h4></li>
                    <li>{t("terms_page.rule_14_b")}</li>
                    <li><h4>{t("terms_page.rule_15_h")}</h4></li>
                    <li>{t("terms_page.rule_15_b")}</li>
                  </ul>
                <div className="about-page-call">
                  <div className="page-call-icon">
                    <FaPhoneAlt />
                  </div>
                  <div className="call-info">
                    <p>{t("need_any_help")}</p>
                    <h4>
                      <a href="tel:+38761147169">+38761147169</a>
                    </h4>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="about-page-right">
                <img src={img1} alt="about page" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="gauto-about-promo section_70">
        <Container>
          <Row>
            <Col md={12}>
              <div className="about-promo-text">
                <h3
                  dangerouslySetInnerHTML={{
                    __html: t("about_page.proud_title", {
                      interpolation: { escapeValue: false },
                    }),
                  }}
                ></h3>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="about-promo-image">
                <img src={img2} alt="about promo" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default TermsPage;
