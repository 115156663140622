import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import { FaCar, FaCogs, FaSignal as BsFuelPump} from "react-icons/fa";

import audiA6 from "../../img/audiA6.png";
import fabia from "../../img/fabia.png";
import octavia from "../../img/octavia.png";
import polo from "../../img/polo.png";
import octavia_caravan from "../../img/octavia_caravan.png";
import passat from "../../img/passat.png";
import passat_station from "../../img/passat_station.png";
import rapid from "../../img/rapid.png";
import tiguan from "../../img/tiguan.png";
import vito from "../../img/vito.png";
import yeti from "../../img/yeti.png";
import touran from "../../img/touran.png";

import "./style.css";

const HotOffers = () => {
  const { t } = useTranslation();

  const onClick = (e) => {
    e.preventDefault();
  };

  return (
    <section className="gauto-offers-area section_70">
      <Container>
        <Row>
          <Col md={12}>
            <div className="site-heading">
              <h4>{t("come_with")}</h4>
              <h2>{t("hot_offers")}</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="offer-tabs" id="offerTab">
              <Tabs
                defaultActiveKey="all"
                transition={true}
                id="uncontrolled-tab-example"
              >
                {/* All Brands Start */}
                <Tab eventKey="all" title={t("all")}>
                  <Row>
                    <Col lg={4}>
                      <div className="single-offers">
                        <div className="offer-image">
                          <img src={fabia} alt="offer 1" />
                        </div>
                        <div className="offer-text">
                          <h3>Skoda Fabia</h3>
                          <h4>
                          {t("daily_price", {price: "€25.00"})}
                          </h4>
                          <ul>
                            <li>
                              <FaCar />
                              {t("model")}:2018
                            </li>
                            <li>
                              <FaCogs />
                              {t("manual")}
                            </li>
                            <li>
                              <BsFuelPump  />
                              {t("diesel")}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="single-offers">
                        <div className="offer-image">
                          <img src={polo} alt="offer 1" />
                        </div>
                        <div className="offer-text">
                          <h3>Volkswagen Polo</h3>
                          <h4>
                          {t("daily_price", {price: "€25.00"})}
                          </h4>
                          <ul>
                            <li>
                              <FaCar />
                              {t("model")}:2018
                            </li>
                            <li>
                              <FaCogs />
                              {t("manual")}
                            </li>
                            <li>
                              <BsFuelPump  />
                              {t("diesel")}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="single-offers">
                        <div className="offer-image">
                          <img src={rapid} alt="offer 1" />
                        </div>
                        <div className="offer-text">
                          <h3>Skoda Rapid</h3>
                          <h4>
                          {t("daily_price", {price: "€28.00"})}
                          </h4>
                          <ul>
                            <li>
                              <FaCar />
                              {t("model")}:2018
                            </li>
                            <li>
                              <FaCogs />
                              {t("manual")}
                            </li>
                            <li>
                              <BsFuelPump  />
                              {t("gas")}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <div className="single-offers">
                        <div className="offer-image">
                          <img src={octavia} alt="offer 1" />
                        </div>
                        <div className="offer-text">
                          <h3>Skoda Octavia</h3>
                          <h4>
                          {t("daily_price", {price: "€30.00"})}
                          </h4>
                          <ul>
                            <li>
                              <FaCar />
                              {t("model")}:2016
                            </li>
                            <li>
                              <FaCogs />
                              {t("manual")}
                            </li>
                            <li>
                              <BsFuelPump  />
                              {t("diesel")}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="single-offers">
                        <div className="offer-image">
                          <img src={passat} alt="offer 1" />
                        </div>
                        <div className="offer-text">
                          <h3>Volkswagen Passat B8</h3>
                          <h4>
                          {t("daily_price", {price: "€30.00"})}
                          </h4>
                          <ul>
                            <li>
                              <FaCar />
                              {t("model")}:2018
                            </li>
                            <li>
                              <FaCogs />
                              {t("manual")}
                            </li>
                            <li>
                              <BsFuelPump  />
                              {t("diesel")}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="single-offers">
                        <div className="offer-image">
                          <img src={passat_station} alt="offer 1" />
                        </div>
                        <div className="offer-text">
                          <h3>Volkswagen Passat Station Wagon</h3>
                          <h4>
                          {t("daily_price", {price: "€35.00"})}
                          </h4>
                          <ul>
                            <li>
                              <FaCar />
                              {t("model")}:2016
                            </li>
                            <li>
                              <FaCogs />
                              {t("automatic")}
                            </li>
                            <li>
                              <BsFuelPump  />
                              {t("diesel")}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <div className="single-offers">
                        <div className="offer-image">
                          <img src={octavia_caravan} alt="offer 1" />
                        </div>
                        <div className="offer-text">
                          <h3>Skoda Octavia Caravan</h3>
                          <h4>
                          {t("daily_price", {price: "€35.00"})}
                          </h4>
                          <ul>
                            <li>
                              <FaCar />
                              {t("model")}:2018
                            </li>
                            <li>
                              <FaCogs />
                              {t("automatic")}
                            </li>
                            <li>
                              <BsFuelPump  />
                              {t("diesel")}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="single-offers">
                        <div className="offer-image">
                          <img src={touran} alt="offer 1" />
                        </div>
                        <div className="offer-text">
                          <h3>Volkswagen Touran</h3>
                          <h4>
                          €35.00<span>/ {t("day")}</span>
                          </h4>
                          <ul>
                            <li>
                              <FaCar />
                              {t("model")}:2019
                            </li>
                            <li>
                              <FaCogs />
                              {t("automatic")}
                            </li>
                            <li>
                              <BsFuelPump  />
                              {t("diesel")}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="single-offers">
                        <div className="offer-image">
                          <img src={audiA6} alt="offer 1" />
                        </div>
                        <div className="offer-text">
                          <h3>Audi A6</h3>
                          <h4>
                          {t("daily_price", {price: "€60.00"})}
                          </h4>
                          <ul>
                            <li>
                              <FaCar />
                              {t("model")}:2016
                            </li>
                            <li>
                              <FaCogs />
                              {t("automatic")}
                            </li>
                            <li>
                              <BsFuelPump  />
                              {t("diesel")}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <div className="single-offers">
                        <div className="offer-image">
                          <img src={vito} alt="offer 1" />
                        </div>
                        <div className="offer-text">
                          <h3>Mercedes Vito</h3>
                          <h4>
                          {t("daily_price", {price: "€75.00"})}
                          </h4>
                          <ul>
                            <li>
                              <FaCar />
                              {t("model")}:2011
                            </li>
                            <li>
                              <FaCogs />
                              {t("automatic")}
                            </li>
                            <li>
                              <BsFuelPump  />
                              {t("diesel")}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="single-offers">
                        <div className="offer-image">
                          <img src={yeti} alt="offer 1" />
                        </div>
                        <div className="offer-text">
                          <h3>Skoda Yeti</h3>
                          <h4>
                          {t("daily_price", {price: "€40.00"})}
                          </h4>
                          <ul>
                            <li>
                              <FaCar />
                              {t("model")}:2017
                            </li>
                            <li>
                              <FaCogs />
                              {t("automatic")}
                            </li>
                            <li>
                              <BsFuelPump  />
                              {t("diesel")}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="single-offers">
                        <div className="offer-image">
                          <img src={tiguan} alt="offer 1" />
                        </div>
                        <div className="offer-text">
                          <h3>Volkswagen Tiguan</h3>
                          <h4>
                          {t("daily_price", {price: "€40.00"})}
                          </h4>
                          <ul>
                            <li>
                              <FaCar />
                              {t("model")}:2017
                            </li>
                            <li>
                              <FaCogs />
                              {t("automatic")}
                            </li>
                            <li>
                              <BsFuelPump  />
                              {t("diesel")}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Tab>
                {/* All Brands End */}

                {/* Nissan Start */}
                <Tab eventKey="nissan" title={t("economy")}>
                  <Row>
                    <Col lg={4}>
                      <div className="single-offers">
                        <div className="offer-image">
                          <img src={fabia} alt="offer 1" />
                        </div>
                        <div className="offer-text">
                          <h3>Skoda Fabia</h3>
                          <h4>
                          {t("daily_price", {price: "€25.00"})}
                          </h4>
                          <ul>
                            <li>
                              <FaCar />
                              {t("model")}:2018
                            </li>
                            <li>
                              <FaCogs />
                              {t("manual")}
                            </li>
                            <li>
                              <BsFuelPump  />
                              {t("diesel")}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="single-offers">
                        <div className="offer-image">
                          <img src={polo} alt="offer 1" />
                        </div>
                        <div className="offer-text">
                          <h3>Volkswagen Polo</h3>
                          <h4>
                          {t("daily_price", {price: "€25.00"})}
                          </h4>
                          <ul>
                            <li>
                              <FaCar />
                              {t("model")}:2018
                            </li>
                            <li>
                              <FaCogs />
                              {t("manual")}
                            </li>
                            <li>
                              <BsFuelPump  />
                              {t("diesel")}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="single-offers">
                        <div className="offer-image">
                          <img src={rapid} alt="offer 1" />
                        </div>
                        <div className="offer-text">
                          <h3>Skoda Rapid</h3>
                          <h4>
                          {t("daily_price", {price: "€28.00"})}
                          </h4>
                          <ul>
                            <li>
                              <FaCar />
                              {t("model")}:2018
                            </li>
                            <li>
                              <FaCogs />
                              {t("manual")}
                            </li>
                            <li>
                              <BsFuelPump  />
                              {t("gas")}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Tab>
                {/* Nissan End */}

                {/* Toyota Start */}
                <Tab eventKey="toyota" title={t("family")}>
                <Row>
                    <Col lg={4}>
                      <div className="single-offers">
                        <div className="offer-image">
                          <img src={octavia} alt="offer 1" />
                        </div>
                        <div className="offer-text">
                          <h3>Skoda Octavia</h3>
                          <h4>
                          {t("daily_price", {price: "€30.00"})}
                          </h4>
                          <ul>
                            <li>
                              <FaCar />
                              {t("model")}:2016
                            </li>
                            <li>
                              <FaCogs />
                              {t("manual")}
                            </li>
                            <li>
                              <BsFuelPump  />
                              {t("diesel")}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="single-offers">
                        <div className="offer-image">
                          <img src={passat} alt="offer 1" />
                        </div>
                        <div className="offer-text">
                          <h3>Volkswagen Passat B8</h3>
                          <h4>
                          {t("daily_price", {price: "€30.00"})}
                          </h4>
                          <ul>
                            <li>
                              <FaCar />
                              {t("model")}:2018
                            </li>
                            <li>
                              <FaCogs />
                              {t("manual")}
                            </li>
                            <li>
                              <BsFuelPump  />
                              {t("diesel")}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="single-offers">
                        <div className="offer-image">
                          <img src={passat_station} alt="offer 1" />
                        </div>
                        <div className="offer-text">
                          <h3>Volkswagen Passat Station Wagon</h3>
                          <h4>
                          {t("daily_price", {price: "€35.00"})}
                          </h4>
                          <ul>
                            <li>
                              <FaCar />
                              {t("model")}:2016
                            </li>
                            <li>
                              <FaCogs />
                              {t("automatic")}
                            </li>
                            <li>
                              <BsFuelPump  />
                              {t("diesel")}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <div className="single-offers">
                        <div className="offer-image">
                          <img src={octavia_caravan} alt="offer 1" />
                        </div>
                        <div className="offer-text">
                          <h3>Skoda Octavia Caravan</h3>
                          <h4>
                          {t("daily_price", {price: "€35.00"})}
                          </h4>
                          <ul>
                            <li>
                              <FaCar />
                              {t("model")}:2018
                            </li>
                            <li>
                              <FaCogs />
                              {t("automatic")}
                            </li>
                            <li>
                              <BsFuelPump  />
                              {t("diesel")}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="single-offers">
                        <div className="offer-image">
                          <img src={touran} alt="offer 1" />
                        </div>
                        <div className="offer-text">
                          <h3>Volkswagen Touran</h3>
                          <h4>
                          {t("daily_price", {price: "€35.00"})}
                          </h4>
                          <ul>
                            <li>
                              <FaCar />
                              {t("model")}:2019
                            </li>
                            <li>
                              <FaCogs />
                              {t("automatic")}
                            </li>
                            <li>
                              <BsFuelPump  />
                              {t("diesel")}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="single-offers">
                        <div className="offer-image">
                          <img src={yeti} alt="offer 1" />
                        </div>
                        <div className="offer-text">
                          <h3>Skoda Yeti</h3>
                          <h4>
                          {t("daily_price", {price: "€40.00"})}
                          </h4>
                          <ul>
                            <li>
                              <FaCar />
                              {t("model")}:2017
                            </li>
                            <li>
                              <FaCogs />
                              {t("automatic")}
                            </li>
                            <li>
                              <BsFuelPump  />
                              {t("diesel")}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Tab>
                {/* Toyota End */}

                {/* Audi Start */}
                <Tab eventKey="audi" title={t("luxury")}>
                  <Row>
                    <Col lg={4}>
                      <div className="single-offers">
                        <div className="offer-image">
                          <img src={vito} alt="offer 1" />
                        </div>
                        <div className="offer-text">
                          <h3>Mercedes Vito</h3>
                          <h4>
                          {t("daily_price", {price: "€75.00"})}
                          </h4>
                          <ul>
                            <li>
                              <FaCar />
                              {t("model")}:2011
                            </li>
                            <li>
                              <FaCogs />
                              {t("automatic")}
                            </li>
                            <li>
                              <BsFuelPump  />
                              {t("diesel")}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="single-offers">
                        <div className="offer-image">
                          <img src={audiA6} alt="offer 1" />
                        </div>
                        <div className="offer-text">
                          <h3>Audi A6</h3>
                          <h4>
                          {t("daily_price", {price: "€60.00"})}
                          </h4>
                          <ul>
                            <li>
                              <FaCar />
                              {t("model")}:2016
                            </li>
                            <li>
                              <FaCogs />
                              {t("automatic")}
                            </li>
                            <li>
                              <BsFuelPump  />
                              {t("diesel")}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="single-offers">
                        <div className="offer-image">
                          <img src={tiguan} alt="offer 1" />
                        </div>
                        <div className="offer-text">
                          <h3>Volkswagen Tiguan</h3>
                          <h4>
                          {t("daily_price", {price: "€40.00"})}
                          </h4>
                          <ul>
                            <li>
                              <FaCar />
                              {t("model")}:2017
                            </li>
                            <li>
                              <FaCogs />
                              {t("automatic")}
                            </li>
                            <li>
                              <BsFuelPump  />
                              {t("diesel")}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Tab>
                {/* Audi End */}
              </Tabs>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HotOffers;
