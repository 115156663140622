import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import {
  FaFacebook,
  FaLinkedin,
  FaPaperPlane,
  FaPinterest,
  FaSkype,
  FaTwitter,
  FaVimeo,
} from "react-icons/fa";

import "./style.css";

const Contact = () => {
  const { t } = useTranslation();

  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  const onClick = (e) => {
    e.preventDefault();
  };

  return (
    <section className="gauto-contact-area section_70">
      <Container>
        <Row>
          <Col lg={7} md={6}>
            <div className="contact-left">
            <h3>{t("contact_page.info_title")} </h3>
              <div className="contact-details">
                <p>
                  <i className="fa fa-map-marker" />
                  Dzemala Bijedica St 185, Sarajevo 71000
                </p>
                <div className="single-contact-btn">
                  <h4>{t("contact_page.info_email")}</h4>
                  <a href="mailto:edessarentacar@gmail.com">edessarentacar@gmail.com</a>
                </div>
                <div className="single-contact-btn">
                  <h4>{t("contact_page.info_call")}</h4>
                  <a href="tel:+38761147169">+(387)61147169</a>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={5} md={6}>
            <div className="contact-right">
              
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Contact;
